import { request as axios } from '@/util/request';
import { formatDate } from '@/util/global'
const clean = require('bmh/clean-empty-obj');
const getUrl = require('bmh/url-with-param');

interface QuerySendData {
    name?: string;
    receiver?: string;
    receiver_mobile?: number;
    page?: number;
} 

const API = {
    list: '/boss/factory/refund',
    repo: '/boss/warehouse/warehouse',
    factoryList: '/boss/factory',
    pick: '/boss/factory/refund/pick'
};

function formatWare(remote: any = {}) {
    return {
      current: remote.current || 1,
      limit: remote.limit || 10,
      detail: remote.detail || [],
      total: remote.total || 0,
      last: remote.last || 1,
    };
}

export function formatWareData(remote: any = {}) {
    return {
        ...remote,
        create_time: formatDate(remote.create_time),
    };
}

function formatRepoData(remote: any = {}) {
    return {
      repo_id: remote.id,
      repo_name: remote.name,
    };
}

/**
 * 查询进货数据
 * @param current 当前分页
 */
async function queryPurchase(current: number = 1, send_data?: QuerySendData) {
    clean(send_data);
    const _send_data = {
      ...send_data,
      // limit:2,
      current,
    };
    const url = getUrl(API.list, _send_data);
    const res = await axios.get(url);
    if (res.status !== 200) {
      //message.error((res as any).message);
    }
    const list = formatWare(res.data);
    list.detail = list.detail.map((item: any) => formatWareData(item));
    return list;
}

/**
 * 进货详情
 * @param id 进货 id
 */
async function detailPurchase(id: number) {
    const url = getUrl(`${API.list}/${id}`);
    const res = await axios.get(url);
    if (res.status !== 200) {
      //message.error((res as any).message);
    }
    return res
}

/**
 * 查询门店列表带分页
 * @param current 当前页数
 * @param is_paginate 是否分页，默认 true
 */
async function queryRepoAndPaginate(current: number = 1,body?: any) {
    const res = await axios.get(API.repo, {
      params: {
        current,
        is_page: 1,
        limit: 9999,
        ...body
      },
    });
    const repo = formatWare(res.data);
    repo.detail = repo.detail.map((item: any) => formatRepoData(item));
    return repo;
}

/**
 * 查询工厂数据
 * @param current 当前分页
 */
 async function queryFactory(current: number = 1) {
    const _send_data = {
      limit:9999,
      current,
    };
    const url = getUrl(API.factoryList, _send_data);
    const res = await axios.get(url);
    if (res.status !== 200) {
      //message.error((res as any).message);
    }
    // console.log(res)
    return res.data.detail;
}

/**
 * 进货接单
 * @param id 进货 id
 */
 async function pickPurchase(id: number, data: any) {
    return await axios.put(`${API.pick}/${id}`, data);
}

  

export {
    queryPurchase,
    detailPurchase,
    queryRepoAndPaginate,
    queryFactory,
    pickPurchase
};
